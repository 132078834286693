html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}


article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* { box-sizing: border-box; }



.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
}

.ant-table, .ant-steps, .ant-form-item, .ant-dropdown, .ant-card, .ant-modal, .ant-message, .ant-select, .ant-calendar {
  font-family: 'Balsamiq Sans', cursive;
}

input, select { cursor: pointer; }
form label, form input { cursor: pointer; }

body { scroll-behavior: smooth; }
body { overflow-x: hidden; }

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.18);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.18);
  background-color: #F1F1F1;
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}
body::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
}
body::-webkit-scrollbar-thumb:hover { background-color: #A8A8A8;  }
body::-webkit-scrollbar-thumb:active { background-color: #787878; }

.ant-form-item { margin-bottom: 30px; }
.ant-form-item-explain, .ant-form-item-extra { margin-top: 6px; line-height: 100%; }
.ant-dropdown a.active { background: #daedff; }
