@import '~antd/dist/antd.css';
@import './reset.css';

@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap');

body {
  background: #f7f8fa;
  color: #000;
  font-size: 16px;
  font-family: 'Balsamiq Sans', cursive;
}




